import request from "@/utils/request"
import commonApi from "@/api/api"

// 动态生成 API 路径
function generateApiPath(moduleName, path) {
	return `${moduleName}${path}`
}

const moduleName = "/project"

export default {
	...commonApi(moduleName),
	// 获取项目下的文件列表
	apiGetList: async data => {
		try {
			if (!data || !data.projectId) {
				throw new Error("Invalid projectId")
			}
			const response = await request(
				generateApiPath(moduleName, `/${data.projectId}/docfiles/zips`),
				{ method: "get" }
			)
			return response
		} catch (error) {
			console.error("Error fetching list:", error)
			throw error // 重新抛出错误以便外部处理
		}
	},
	// 删除打包文件
	apiDeleteFile: async data => {
		try {
			if (!data || !data.projectId || !data.docfileId) {
				throw new Error("Invalid projectId or docfileId")
			}
			const response = await request(
				generateApiPath(
					moduleName,
					`/${data.projectId}/docfiles/zips/${encodeURIComponent(data.docfileId)}`
				),
				{
					method: "delete"
				}
			)
			return response
		} catch (error) {
			console.error("Error deleting file:", error)
			throw error // 重新抛出错误以便外部处理
		}
	}
}
