<template>
	<CtmsDataGrid
		:height="dataGridHeight"
		:columns="columns"
		:loading="loading"
		:data="listData"
		:page="false"
	></CtmsDataGrid>
</template>
<script>
import { mapState } from "vuex"
import api from "@/api/project/packageFile"
import { transformSizeUnit } from "@/utils/util"
import zipImg from "@/assets/common/zip.png"

const { apiGetList, apiDeleteFile } = api

export default {
	data() {
		return {
			loading: false,
			columns: [
				{
					title: "名称",
					key: "name",
					minWidth: 300,
					render: this.selDocment
				},
				{
					title: "大小",
					key: "size",
					minWidth: 150,
					render: (h, params) =>
						h(
							"span",
							params.row.size && params.row.status === 1
								? `${transformSizeUnit(params.row.size)}`
								: "--"
						)
				},
				{
					title: "创建日期",
					key: "createdTime",
					minWidth: 180
				},
				{
					title: "状态",
					key: "status",
					minWidth: 120,
					render: (h, params) => {
						let txt = ""
						if (params.row.status) {
							txt = params.row.status === 1 ? "已打包" : "打包失败"
						} else {
							txt = "打包中"
						}
						return h("span", txt)
					}
				},
				{
					title: "操作",
					key: "action",
					fixed: "right",
					width: 120,
					renderButton: params => {
						const actionList = [
							{
								label: "下载",
								on: {
									click: () => {
										window.open(
											`${this.$baseUrl}/project/${this.$route.params.projectId}/docfiles/zip/${params.row.id}/download?token=${this.$store.state.user.token}`
										)
									}
								}
							},
							{
								label: "删除",
								on: {
									click: ({ row }) => {
										this.$Modal.confirm({
											title: "提示",
											content: "<p>确认要删除该打包文件吗？</p>",
											loading: true,
											onOk: () => {
												this.$asyncDo(async () => {
													const res = await apiDeleteFile({
														projectId: row.projectId,
														docfileId: row.id
													})
													if (res) {
														this.$Message.info("删除成功")
														this.getPackageList()
													}
													this.$Modal.remove()
												})
											},
											onCancel: () => {}
										})
									}
								}
							}
						]
						if (params.row.status === 0 || params.row.status === 2) {
							// 打包中打包失败都不能进行下载
							actionList[0] = null
							actionList[1] = null
						}
						if (
							this.projectActionPermissions.indexOf(
								"btn_project_document_zip"
							) === -1
						) {
							// 下载打包权限限制
							actionList[0] = null
							actionList[1] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			listData: []
		}
	},
	methods: {
		getPackageList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetList({
					projectId: this.$route.params.projectId
				})
				if (res) {
					this.listData = res.data
				}
				this.loading = false
			})
		},
		selDocment(h, params) {
			return h("div", [
				h("img", {
					attrs: {
						src: zipImg
					},
					style: {
						width: "50px",
						display: "inline",
						cursor: "pointer",
						padding: "8px"
					}
				}),
				h(
					"div",
					{
						style: {
							display: "inline-flex",
							width: "200px",
							padding: "8px 0 8px 0"
						},
						title: params.row.name
					},
					params.row.name
				)
			])
		}
	},
	created() {
		this.getPackageList()
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - this.infoHeight
			}
		}),
		...mapState("permission", ["projectActionPermissions"])
	}
}
</script>
